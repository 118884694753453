html {
  box-sizing: border-box;
  background: background;
  color: #363f4f;
  scroll-behavior: smooth;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

strong, b {
  font-weight: 700;
}

img, video {
  height: auto;
  max-width: 100%;
  display: block;
}

iframe {
  border: 0;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #0000;
  border-width: 0;
  margin-bottom: 2rem;
  display: table;
}

table tr {
  box-sizing: border-box;
  display: table-row;
}

table th, table td {
  box-sizing: border-box;
  line-height: inherit;
  padding: 1rem;
  display: table-cell;
}

table thead {
  box-sizing: border-box;
  display: table-header-group;
}

table thead th {
  text-align: left;
  vertical-align: bottom;
  border-bottom: .125rem solid #dadadd;
  font-weight: bold;
}

table tbody {
  vertical-align: middle;
  display: table-row-group;
}

table tbody td {
  vertical-align: top;
  border-top: .125rem solid #dadadd;
}

h1 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

h2 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

h3 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

h4 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

h5 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

h6 {
  color: #1d2734;
  margin-bottom: .5rem;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

a {
  color: #1272ce;
}

a:hover {
  color: #d9308d;
}

small {
  font-size: .875rem;
}

pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  overflow-x: auto;
}

code {
  color: #1272ce;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .875rem;
  font-weight: 700;
  text-decoration: none;
}

hr {
  height: .125rem;
  background-color: #dadadd;
  border: 0;
  padding: 0;
  display: block;
}

hr:not(:first-child) {
  margin-top: 1rem;
}

hr:not(:last-child) {
  margin-bottom: 1rem;
}

blockquote {
  color: currentColor;
  opacity: .6;
  border-left: .25rem solid;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  padding: .75rem .75rem .75rem 1.25rem;
  display: block;
}

.columns {
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}

.container {
  max-width: 1264px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: block;
}

.container.is-readable {
  max-width: 45rem;
}

.crumbs {
  border-radius: default;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  padding: .5rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap !important;
}

.divider {
  height: .125rem;
  background-color: #dadadd;
  border: 0;
  padding: 0;
  display: block;
}

.divider:not(:first-child) {
  margin-top: 1rem;
}

.divider:not(:last-child) {
  margin-bottom: 1rem;
}

.paragraph {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
}

.paragraph.is-lead {
  font-size: 1.25rem;
  font-weight: bold;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #0000;
  border-width: 0;
  margin-bottom: 2rem;
  display: table;
}

.table tr {
  box-sizing: border-box;
  display: table-row;
}

.table th, .table td {
  box-sizing: border-box;
  line-height: inherit;
  padding: 1rem;
  display: table-cell;
}

.table thead {
  box-sizing: border-box;
  display: table-header-group;
}

.table thead th {
  text-align: left;
  vertical-align: bottom;
  border-bottom: .125rem solid #dadadd;
  font-weight: bold;
}

.table tbody {
  vertical-align: middle;
  display: table-row-group;
}

.table tbody td {
  vertical-align: top;
  border-top: .125rem solid #dadadd;
}

.table.is-divided thead th {
  border-bottom: .125rem solid #dadadd;
}

.table.is-divided tbody td {
  border-top: .125rem solid #dadadd;
}

.table.is-bordered th, .table.is-bordered td {
  border: .125rem solid #dadadd;
}

.table.is-bordered th:not(:last-child), .table.is-bordered td:not(:last-child) {
  border-right-width: 0 !important;
}

.table.is-bordered tbody tr:not(:last-child) td {
  border-bottom-width: 0 !important;
}

.table.is-fixed {
  table-layout: fixed;
}

.title {
  color: #1d2734;
  margin-bottom: .5em;
  padding: 0;
  font-family: inherit;
  font-weight: 900;
  line-height: 1.25;
  display: block;
}

.title:not(:first-child) {
  margin-top: 2rem;
}

.title.is-1 {
  font-size: 2.5rem;
}

.title.is-2 {
  font-size: 2rem;
}

.title.is-3 {
  font-size: 1.75rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 16px;
}

.has-bg-white, .has-bg-white-hover:hover {
  background-color: #fff;
}

.has-bg-black, .has-bg-black-hover:hover {
  background-color: #000;
}

.has-bg-current, .has-bg-current-hover:hover {
  background-color: currentColor;
}

.has-bg-transparent, .has-bg-transparent-hover:hover {
  background-color: #0000;
}

.has-bg-primary, .has-bg-primary-hover:hover {
  background-color: #1272ce;
}

.has-bg-secondary, .has-bg-secondary-hover:hover {
  background-color: #d9308d;
}

.has-bg-highlight, .has-bg-highlight-hover:hover {
  background-color: #38b7d3;
}

.has-bg-muted, .has-bg-muted-hover:hover {
  background-color: #dadadd;
}

.has-bg-dark, .has-bg-dark-hover:hover {
  background-color: #1d2734;
}

.has-bg-light, .has-bg-light-hover:hover {
  background-color: #f6f7f9;
}

.has-text-white, .has-text-white-hover:hover {
  color: #fff;
}

.has-text-black, .has-text-black-hover:hover {
  color: #000;
}

.has-text-current, .has-text-current-hover:hover {
  color: currentColor;
}

.has-text-transparent, .has-text-transparent-hover:hover {
  color: #0000;
}

.has-text-primary, .has-text-primary-hover:hover {
  color: #1272ce;
}

.has-text-secondary, .has-text-secondary-hover:hover {
  color: #d9308d;
}

.has-text-highlight, .has-text-highlight-hover:hover {
  color: #38b7d3;
}

.has-text-muted, .has-text-muted-hover:hover {
  color: #dadadd;
}

.has-text-dark, .has-text-dark-hover:hover {
  color: #1d2734;
}

.has-text-light, .has-text-light-hover:hover {
  color: #f6f7f9;
}

.has-size-0 {
  font-size: .875rem;
}

.has-size-1 {
  font-size: 16px;
}

.has-size-2 {
  font-size: 1.25rem;
}

.has-size-3 {
  font-size: 1.5rem;
}

.has-size-4 {
  font-size: 1.75rem;
}

.has-size-5 {
  font-size: 2rem;
}

.has-size-6 {
  font-size: 2.5rem;
}

.has-size-7 {
  font-size: 3rem;
}

.has-size-8 {
  font-size: 3.5rem;
}

.has-size-9 {
  font-size: 4rem;
}

.has-size-10 {
  font-size: 4.5rem;
}

.has-weight-light {
  font-weight: 300;
}

.has-weight-normal {
  font-weight: 400;
}

.has-weight-medium {
  font-weight: 500;
}

.has-weight-bold {
  font-weight: 700;
}

.has-weight-black {
  font-weight: 900;
}

.has-lh-none {
  line-height: 1;
}

.has-lh-tight {
  line-height: 1.25;
}

.has-lh-normal {
  line-height: 1.5;
}

.has-lh-loose {
  line-height: 2;
}

.has-w-0 {
  width: 0;
}

@media screen and (max-width: 640px) {
  .has-w-0-mobile {
    width: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-w-0-tablet {
    width: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-0-desktop {
    width: 0;
  }
}

.has-w-4 {
  width: 1rem;
}

@media screen and (max-width: 640px) {
  .has-w-4-mobile {
    width: 1rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-4-tablet {
    width: 1rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-4-desktop {
    width: 1rem;
  }
}

.has-w-8 {
  width: 2rem;
}

@media screen and (max-width: 640px) {
  .has-w-8-mobile {
    width: 2rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-8-tablet {
    width: 2rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-8-desktop {
    width: 2rem;
  }
}

.has-w-12 {
  width: 3rem;
}

@media screen and (max-width: 640px) {
  .has-w-12-mobile {
    width: 3rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-12-tablet {
    width: 3rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-12-desktop {
    width: 3rem;
  }
}

.has-w-16 {
  width: 4rem;
}

@media screen and (max-width: 640px) {
  .has-w-16-mobile {
    width: 4rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-16-tablet {
    width: 4rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-16-desktop {
    width: 4rem;
  }
}

.has-w-24 {
  width: 6rem;
}

@media screen and (max-width: 640px) {
  .has-w-24-mobile {
    width: 6rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-24-tablet {
    width: 6rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-24-desktop {
    width: 6rem;
  }
}

.has-w-32 {
  width: 8rem;
}

@media screen and (max-width: 640px) {
  .has-w-32-mobile {
    width: 8rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-32-tablet {
    width: 8rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-32-desktop {
    width: 8rem;
  }
}

.has-w-48 {
  width: 12rem;
}

@media screen and (max-width: 640px) {
  .has-w-48-mobile {
    width: 12rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-48-tablet {
    width: 12rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-48-desktop {
    width: 12rem;
  }
}

.has-w-64 {
  width: 16rem;
}

@media screen and (max-width: 640px) {
  .has-w-64-mobile {
    width: 16rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-64-tablet {
    width: 16rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-64-desktop {
    width: 16rem;
  }
}

.has-w-96 {
  width: 24rem;
}

@media screen and (max-width: 640px) {
  .has-w-96-mobile {
    width: 24rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-96-tablet {
    width: 24rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-96-desktop {
    width: 24rem;
  }
}

.has-w-192 {
  width: 48rem;
}

@media screen and (max-width: 640px) {
  .has-w-192-mobile {
    width: 48rem;
  }
}

@media screen and (min-width: 640px) {
  .has-w-192-tablet {
    width: 48rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-192-desktop {
    width: 48rem;
  }
}

.has-w-none {
  width: 0;
}

@media screen and (max-width: 640px) {
  .has-w-none-mobile {
    width: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-w-none-tablet {
    width: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-none-desktop {
    width: 0;
  }
}

.has-w-half {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .has-w-half-mobile {
    width: 50%;
  }
}

@media screen and (min-width: 640px) {
  .has-w-half-tablet {
    width: 50%;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-half-desktop {
    width: 50%;
  }
}

.has-w-full {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .has-w-full-mobile {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .has-w-full-tablet {
    width: 100%;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-full-desktop {
    width: 100%;
  }
}

.has-w-auto {
  width: auto;
}

@media screen and (max-width: 640px) {
  .has-w-auto-mobile {
    width: auto;
  }
}

@media screen and (min-width: 640px) {
  .has-w-auto-tablet {
    width: auto;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-auto-desktop {
    width: auto;
  }
}

.has-w-screen {
  width: 100vw;
}

@media screen and (max-width: 640px) {
  .has-w-screen-mobile {
    width: 100vw;
  }
}

@media screen and (min-width: 640px) {
  .has-w-screen-tablet {
    width: 100vw;
  }
}

@media screen and (min-width: 1264px) {
  .has-w-screen-desktop {
    width: 100vw;
  }
}

.has-h-0 {
  height: 0;
}

@media screen and (max-width: 640px) {
  .has-h-0-mobile {
    height: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-h-0-tablet {
    height: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-0-desktop {
    height: 0;
  }
}

.has-h-4 {
  height: 1rem;
}

@media screen and (max-width: 640px) {
  .has-h-4-mobile {
    height: 1rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-4-tablet {
    height: 1rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-4-desktop {
    height: 1rem;
  }
}

.has-h-8 {
  height: 2rem;
}

@media screen and (max-width: 640px) {
  .has-h-8-mobile {
    height: 2rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-8-tablet {
    height: 2rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-8-desktop {
    height: 2rem;
  }
}

.has-h-12 {
  height: 3rem;
}

@media screen and (max-width: 640px) {
  .has-h-12-mobile {
    height: 3rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-12-tablet {
    height: 3rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-12-desktop {
    height: 3rem;
  }
}

.has-h-16 {
  height: 4rem;
}

@media screen and (max-width: 640px) {
  .has-h-16-mobile {
    height: 4rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-16-tablet {
    height: 4rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-16-desktop {
    height: 4rem;
  }
}

.has-h-24 {
  height: 6rem;
}

@media screen and (max-width: 640px) {
  .has-h-24-mobile {
    height: 6rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-24-tablet {
    height: 6rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-24-desktop {
    height: 6rem;
  }
}

.has-h-32 {
  height: 8rem;
}

@media screen and (max-width: 640px) {
  .has-h-32-mobile {
    height: 8rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-32-tablet {
    height: 8rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-32-desktop {
    height: 8rem;
  }
}

.has-h-48 {
  height: 12rem;
}

@media screen and (max-width: 640px) {
  .has-h-48-mobile {
    height: 12rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-48-tablet {
    height: 12rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-48-desktop {
    height: 12rem;
  }
}

.has-h-64 {
  height: 16rem;
}

@media screen and (max-width: 640px) {
  .has-h-64-mobile {
    height: 16rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-64-tablet {
    height: 16rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-64-desktop {
    height: 16rem;
  }
}

.has-h-96 {
  height: 24rem;
}

@media screen and (max-width: 640px) {
  .has-h-96-mobile {
    height: 24rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-96-tablet {
    height: 24rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-96-desktop {
    height: 24rem;
  }
}

.has-h-192 {
  height: 48rem;
}

@media screen and (max-width: 640px) {
  .has-h-192-mobile {
    height: 48rem;
  }
}

@media screen and (min-width: 640px) {
  .has-h-192-tablet {
    height: 48rem;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-192-desktop {
    height: 48rem;
  }
}

.has-h-none {
  height: 0;
}

@media screen and (max-width: 640px) {
  .has-h-none-mobile {
    height: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-h-none-tablet {
    height: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-none-desktop {
    height: 0;
  }
}

.has-h-half {
  height: 50%;
}

@media screen and (max-width: 640px) {
  .has-h-half-mobile {
    height: 50%;
  }
}

@media screen and (min-width: 640px) {
  .has-h-half-tablet {
    height: 50%;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-half-desktop {
    height: 50%;
  }
}

.has-h-full {
  height: 100%;
}

@media screen and (max-width: 640px) {
  .has-h-full-mobile {
    height: 100%;
  }
}

@media screen and (min-width: 640px) {
  .has-h-full-tablet {
    height: 100%;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-full-desktop {
    height: 100%;
  }
}

.has-h-auto {
  height: auto;
}

@media screen and (max-width: 640px) {
  .has-h-auto-mobile {
    height: auto;
  }
}

@media screen and (min-width: 640px) {
  .has-h-auto-tablet {
    height: auto;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-auto-desktop {
    height: auto;
  }
}

.has-h-screen {
  height: 100vh;
}

@media screen and (max-width: 640px) {
  .has-h-screen-mobile {
    height: 100vh;
  }
}

@media screen and (min-width: 640px) {
  .has-h-screen-tablet {
    height: 100vh;
  }
}

@media screen and (min-width: 1264px) {
  .has-h-screen-desktop {
    height: 100vh;
  }
}

.has-p-0 {
  padding: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-p-0-mobile {
    padding: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-0-tablet {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-0-desktop {
    padding: 0 !important;
  }
}

.has-p-1 {
  padding: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-1-mobile {
    padding: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-1-tablet {
    padding: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-1-desktop {
    padding: .25rem !important;
  }
}

.has-p-2 {
  padding: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-2-mobile {
    padding: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-2-tablet {
    padding: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-2-desktop {
    padding: .5rem !important;
  }
}

.has-p-3 {
  padding: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-3-mobile {
    padding: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-3-tablet {
    padding: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-3-desktop {
    padding: .75rem !important;
  }
}

.has-p-4 {
  padding: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-4-mobile {
    padding: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-4-tablet {
    padding: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-4-desktop {
    padding: 1rem !important;
  }
}

.has-p-6 {
  padding: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-6-mobile {
    padding: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-6-tablet {
    padding: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-6-desktop {
    padding: 1.5rem !important;
  }
}

.has-p-8 {
  padding: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-8-mobile {
    padding: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-8-tablet {
    padding: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-8-desktop {
    padding: 2rem !important;
  }
}

.has-p-12 {
  padding: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-12-mobile {
    padding: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-12-tablet {
    padding: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-12-desktop {
    padding: 3rem !important;
  }
}

.has-p-16 {
  padding: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-16-mobile {
    padding: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-16-tablet {
    padding: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-16-desktop {
    padding: 4rem !important;
  }
}

.has-p-24 {
  padding: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-p-24-mobile {
    padding: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-24-tablet {
    padding: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-24-desktop {
    padding: 6rem !important;
  }
}

.has-p-none {
  padding: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-p-none-mobile {
    padding: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-none-tablet {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-none-desktop {
    padding: 0 !important;
  }
}

.has-p-half {
  padding: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-p-half-mobile {
    padding: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-half-tablet {
    padding: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-half-desktop {
    padding: 50% !important;
  }
}

.has-p-full {
  padding: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-p-full-mobile {
    padding: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-full-tablet {
    padding: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-full-desktop {
    padding: 100% !important;
  }
}

.has-p-auto {
  padding: auto !important;
}

@media screen and (max-width: 640px) {
  .has-p-auto-mobile {
    padding: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-p-auto-tablet {
    padding: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-p-auto-desktop {
    padding: auto !important;
  }
}

.has-pt-0 {
  padding-top: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pt-0-mobile {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-0-tablet {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-0-desktop {
    padding-top: 0 !important;
  }
}

.has-pt-1 {
  padding-top: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-1-mobile {
    padding-top: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-1-tablet {
    padding-top: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-1-desktop {
    padding-top: .25rem !important;
  }
}

.has-pt-2 {
  padding-top: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-2-mobile {
    padding-top: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-2-tablet {
    padding-top: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-2-desktop {
    padding-top: .5rem !important;
  }
}

.has-pt-3 {
  padding-top: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-3-mobile {
    padding-top: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-3-tablet {
    padding-top: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-3-desktop {
    padding-top: .75rem !important;
  }
}

.has-pt-4 {
  padding-top: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-4-mobile {
    padding-top: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-4-tablet {
    padding-top: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-4-desktop {
    padding-top: 1rem !important;
  }
}

.has-pt-6 {
  padding-top: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-6-mobile {
    padding-top: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-6-tablet {
    padding-top: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-6-desktop {
    padding-top: 1.5rem !important;
  }
}

.has-pt-8 {
  padding-top: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-8-mobile {
    padding-top: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-8-tablet {
    padding-top: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-8-desktop {
    padding-top: 2rem !important;
  }
}

.has-pt-12 {
  padding-top: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-12-mobile {
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-12-tablet {
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-12-desktop {
    padding-top: 3rem !important;
  }
}

.has-pt-16 {
  padding-top: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-16-mobile {
    padding-top: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-16-tablet {
    padding-top: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-16-desktop {
    padding-top: 4rem !important;
  }
}

.has-pt-24 {
  padding-top: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-pt-24-mobile {
    padding-top: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-24-tablet {
    padding-top: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-24-desktop {
    padding-top: 6rem !important;
  }
}

.has-pt-none {
  padding-top: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pt-none-mobile {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-none-tablet {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-none-desktop {
    padding-top: 0 !important;
  }
}

.has-pt-half {
  padding-top: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-pt-half-mobile {
    padding-top: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-half-tablet {
    padding-top: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-half-desktop {
    padding-top: 50% !important;
  }
}

.has-pt-full {
  padding-top: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-pt-full-mobile {
    padding-top: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-full-tablet {
    padding-top: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-full-desktop {
    padding-top: 100% !important;
  }
}

.has-pt-auto {
  padding-top: auto !important;
}

@media screen and (max-width: 640px) {
  .has-pt-auto-mobile {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pt-auto-tablet {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pt-auto-desktop {
    padding-top: auto !important;
  }
}

.has-pb-0 {
  padding-bottom: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pb-0-mobile {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-0-tablet {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-0-desktop {
    padding-bottom: 0 !important;
  }
}

.has-pb-1 {
  padding-bottom: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-1-mobile {
    padding-bottom: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-1-tablet {
    padding-bottom: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-1-desktop {
    padding-bottom: .25rem !important;
  }
}

.has-pb-2 {
  padding-bottom: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-2-mobile {
    padding-bottom: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-2-tablet {
    padding-bottom: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-2-desktop {
    padding-bottom: .5rem !important;
  }
}

.has-pb-3 {
  padding-bottom: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-3-mobile {
    padding-bottom: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-3-tablet {
    padding-bottom: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-3-desktop {
    padding-bottom: .75rem !important;
  }
}

.has-pb-4 {
  padding-bottom: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-4-mobile {
    padding-bottom: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-4-tablet {
    padding-bottom: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-4-desktop {
    padding-bottom: 1rem !important;
  }
}

.has-pb-6 {
  padding-bottom: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-6-mobile {
    padding-bottom: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-6-tablet {
    padding-bottom: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-6-desktop {
    padding-bottom: 1.5rem !important;
  }
}

.has-pb-8 {
  padding-bottom: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-8-mobile {
    padding-bottom: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-8-tablet {
    padding-bottom: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-8-desktop {
    padding-bottom: 2rem !important;
  }
}

.has-pb-12 {
  padding-bottom: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-12-mobile {
    padding-bottom: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-12-tablet {
    padding-bottom: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-12-desktop {
    padding-bottom: 3rem !important;
  }
}

.has-pb-16 {
  padding-bottom: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-16-mobile {
    padding-bottom: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-16-tablet {
    padding-bottom: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-16-desktop {
    padding-bottom: 4rem !important;
  }
}

.has-pb-24 {
  padding-bottom: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-pb-24-mobile {
    padding-bottom: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-24-tablet {
    padding-bottom: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-24-desktop {
    padding-bottom: 6rem !important;
  }
}

.has-pb-none {
  padding-bottom: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pb-none-mobile {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-none-tablet {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-none-desktop {
    padding-bottom: 0 !important;
  }
}

.has-pb-half {
  padding-bottom: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-pb-half-mobile {
    padding-bottom: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-half-tablet {
    padding-bottom: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-half-desktop {
    padding-bottom: 50% !important;
  }
}

.has-pb-full {
  padding-bottom: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-pb-full-mobile {
    padding-bottom: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-full-tablet {
    padding-bottom: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-full-desktop {
    padding-bottom: 100% !important;
  }
}

.has-pb-auto {
  padding-bottom: auto !important;
}

@media screen and (max-width: 640px) {
  .has-pb-auto-mobile {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pb-auto-tablet {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pb-auto-desktop {
    padding-bottom: auto !important;
  }
}

.has-pl-0 {
  padding-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pl-0-mobile {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-0-tablet {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-0-desktop {
    padding-left: 0 !important;
  }
}

.has-pl-1 {
  padding-left: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-1-mobile {
    padding-left: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-1-tablet {
    padding-left: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-1-desktop {
    padding-left: .25rem !important;
  }
}

.has-pl-2 {
  padding-left: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-2-mobile {
    padding-left: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-2-tablet {
    padding-left: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-2-desktop {
    padding-left: .5rem !important;
  }
}

.has-pl-3 {
  padding-left: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-3-mobile {
    padding-left: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-3-tablet {
    padding-left: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-3-desktop {
    padding-left: .75rem !important;
  }
}

.has-pl-4 {
  padding-left: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-4-mobile {
    padding-left: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-4-tablet {
    padding-left: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-4-desktop {
    padding-left: 1rem !important;
  }
}

.has-pl-6 {
  padding-left: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-6-mobile {
    padding-left: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-6-tablet {
    padding-left: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-6-desktop {
    padding-left: 1.5rem !important;
  }
}

.has-pl-8 {
  padding-left: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-8-mobile {
    padding-left: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-8-tablet {
    padding-left: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-8-desktop {
    padding-left: 2rem !important;
  }
}

.has-pl-12 {
  padding-left: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-12-mobile {
    padding-left: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-12-tablet {
    padding-left: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-12-desktop {
    padding-left: 3rem !important;
  }
}

.has-pl-16 {
  padding-left: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-16-mobile {
    padding-left: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-16-tablet {
    padding-left: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-16-desktop {
    padding-left: 4rem !important;
  }
}

.has-pl-24 {
  padding-left: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-pl-24-mobile {
    padding-left: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-24-tablet {
    padding-left: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-24-desktop {
    padding-left: 6rem !important;
  }
}

.has-pl-none {
  padding-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pl-none-mobile {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-none-tablet {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-none-desktop {
    padding-left: 0 !important;
  }
}

.has-pl-half {
  padding-left: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-pl-half-mobile {
    padding-left: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-half-tablet {
    padding-left: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-half-desktop {
    padding-left: 50% !important;
  }
}

.has-pl-full {
  padding-left: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-pl-full-mobile {
    padding-left: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-full-tablet {
    padding-left: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-full-desktop {
    padding-left: 100% !important;
  }
}

.has-pl-auto {
  padding-left: auto !important;
}

@media screen and (max-width: 640px) {
  .has-pl-auto-mobile {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pl-auto-tablet {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pl-auto-desktop {
    padding-left: auto !important;
  }
}

.has-pr-0 {
  padding-right: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pr-0-mobile {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-0-tablet {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-0-desktop {
    padding-right: 0 !important;
  }
}

.has-pr-1 {
  padding-right: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-1-mobile {
    padding-right: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-1-tablet {
    padding-right: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-1-desktop {
    padding-right: .25rem !important;
  }
}

.has-pr-2 {
  padding-right: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-2-mobile {
    padding-right: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-2-tablet {
    padding-right: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-2-desktop {
    padding-right: .5rem !important;
  }
}

.has-pr-3 {
  padding-right: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-3-mobile {
    padding-right: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-3-tablet {
    padding-right: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-3-desktop {
    padding-right: .75rem !important;
  }
}

.has-pr-4 {
  padding-right: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-4-mobile {
    padding-right: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-4-tablet {
    padding-right: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-4-desktop {
    padding-right: 1rem !important;
  }
}

.has-pr-6 {
  padding-right: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-6-mobile {
    padding-right: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-6-tablet {
    padding-right: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-6-desktop {
    padding-right: 1.5rem !important;
  }
}

.has-pr-8 {
  padding-right: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-8-mobile {
    padding-right: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-8-tablet {
    padding-right: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-8-desktop {
    padding-right: 2rem !important;
  }
}

.has-pr-12 {
  padding-right: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-12-mobile {
    padding-right: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-12-tablet {
    padding-right: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-12-desktop {
    padding-right: 3rem !important;
  }
}

.has-pr-16 {
  padding-right: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-16-mobile {
    padding-right: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-16-tablet {
    padding-right: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-16-desktop {
    padding-right: 4rem !important;
  }
}

.has-pr-24 {
  padding-right: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-pr-24-mobile {
    padding-right: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-24-tablet {
    padding-right: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-24-desktop {
    padding-right: 6rem !important;
  }
}

.has-pr-none {
  padding-right: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-pr-none-mobile {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-none-tablet {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-none-desktop {
    padding-right: 0 !important;
  }
}

.has-pr-half {
  padding-right: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-pr-half-mobile {
    padding-right: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-half-tablet {
    padding-right: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-half-desktop {
    padding-right: 50% !important;
  }
}

.has-pr-full {
  padding-right: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-pr-full-mobile {
    padding-right: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-full-tablet {
    padding-right: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-full-desktop {
    padding-right: 100% !important;
  }
}

.has-pr-auto {
  padding-right: auto !important;
}

@media screen and (max-width: 640px) {
  .has-pr-auto-mobile {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-pr-auto-tablet {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-pr-auto-desktop {
    padding-right: auto !important;
  }
}

.has-m-0 {
  margin: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-m-0-mobile {
    margin: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-0-tablet {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-0-desktop {
    margin: 0 !important;
  }
}

.has-m-1 {
  margin: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-1-mobile {
    margin: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-1-tablet {
    margin: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-1-desktop {
    margin: .25rem !important;
  }
}

.has-m-2 {
  margin: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-2-mobile {
    margin: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-2-tablet {
    margin: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-2-desktop {
    margin: .5rem !important;
  }
}

.has-m-3 {
  margin: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-3-mobile {
    margin: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-3-tablet {
    margin: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-3-desktop {
    margin: .75rem !important;
  }
}

.has-m-4 {
  margin: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-4-mobile {
    margin: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-4-tablet {
    margin: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-4-desktop {
    margin: 1rem !important;
  }
}

.has-m-6 {
  margin: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-6-mobile {
    margin: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-6-tablet {
    margin: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-6-desktop {
    margin: 1.5rem !important;
  }
}

.has-m-8 {
  margin: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-8-mobile {
    margin: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-8-tablet {
    margin: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-8-desktop {
    margin: 2rem !important;
  }
}

.has-m-12 {
  margin: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-12-mobile {
    margin: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-12-tablet {
    margin: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-12-desktop {
    margin: 3rem !important;
  }
}

.has-m-16 {
  margin: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-16-mobile {
    margin: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-16-tablet {
    margin: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-16-desktop {
    margin: 4rem !important;
  }
}

.has-m-24 {
  margin: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-m-24-mobile {
    margin: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-24-tablet {
    margin: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-24-desktop {
    margin: 6rem !important;
  }
}

.has-m-none {
  margin: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-m-none-mobile {
    margin: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-none-tablet {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-none-desktop {
    margin: 0 !important;
  }
}

.has-m-half {
  margin: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-m-half-mobile {
    margin: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-half-tablet {
    margin: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-half-desktop {
    margin: 50% !important;
  }
}

.has-m-full {
  margin: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-m-full-mobile {
    margin: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-full-tablet {
    margin: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-full-desktop {
    margin: 100% !important;
  }
}

.has-m-auto {
  margin: auto !important;
}

@media screen and (max-width: 640px) {
  .has-m-auto-mobile {
    margin: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-m-auto-tablet {
    margin: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-m-auto-desktop {
    margin: auto !important;
  }
}

.has-mt-0 {
  margin-top: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mt-0-mobile {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-0-tablet {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-0-desktop {
    margin-top: 0 !important;
  }
}

.has-mt-1 {
  margin-top: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-1-mobile {
    margin-top: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-1-tablet {
    margin-top: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-1-desktop {
    margin-top: .25rem !important;
  }
}

.has-mt-2 {
  margin-top: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-2-mobile {
    margin-top: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-2-tablet {
    margin-top: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-2-desktop {
    margin-top: .5rem !important;
  }
}

.has-mt-3 {
  margin-top: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-3-mobile {
    margin-top: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-3-tablet {
    margin-top: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-3-desktop {
    margin-top: .75rem !important;
  }
}

.has-mt-4 {
  margin-top: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-4-mobile {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-4-tablet {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-4-desktop {
    margin-top: 1rem !important;
  }
}

.has-mt-6 {
  margin-top: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-6-mobile {
    margin-top: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-6-tablet {
    margin-top: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-6-desktop {
    margin-top: 1.5rem !important;
  }
}

.has-mt-8 {
  margin-top: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-8-mobile {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-8-tablet {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-8-desktop {
    margin-top: 2rem !important;
  }
}

.has-mt-12 {
  margin-top: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-12-mobile {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-12-tablet {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-12-desktop {
    margin-top: 3rem !important;
  }
}

.has-mt-16 {
  margin-top: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-16-mobile {
    margin-top: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-16-tablet {
    margin-top: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-16-desktop {
    margin-top: 4rem !important;
  }
}

.has-mt-24 {
  margin-top: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-mt-24-mobile {
    margin-top: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-24-tablet {
    margin-top: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-24-desktop {
    margin-top: 6rem !important;
  }
}

.has-mt-none {
  margin-top: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mt-none-mobile {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-none-tablet {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-none-desktop {
    margin-top: 0 !important;
  }
}

.has-mt-half {
  margin-top: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-mt-half-mobile {
    margin-top: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-half-tablet {
    margin-top: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-half-desktop {
    margin-top: 50% !important;
  }
}

.has-mt-full {
  margin-top: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-mt-full-mobile {
    margin-top: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-full-tablet {
    margin-top: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-full-desktop {
    margin-top: 100% !important;
  }
}

.has-mt-auto {
  margin-top: auto !important;
}

@media screen and (max-width: 640px) {
  .has-mt-auto-mobile {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mt-auto-tablet {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mt-auto-desktop {
    margin-top: auto !important;
  }
}

.has-mb-0 {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mb-0-mobile {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-0-tablet {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-0-desktop {
    margin-bottom: 0 !important;
  }
}

.has-mb-1 {
  margin-bottom: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-1-mobile {
    margin-bottom: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-1-tablet {
    margin-bottom: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-1-desktop {
    margin-bottom: .25rem !important;
  }
}

.has-mb-2 {
  margin-bottom: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-2-mobile {
    margin-bottom: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-2-tablet {
    margin-bottom: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-2-desktop {
    margin-bottom: .5rem !important;
  }
}

.has-mb-3 {
  margin-bottom: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-3-mobile {
    margin-bottom: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-3-tablet {
    margin-bottom: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-3-desktop {
    margin-bottom: .75rem !important;
  }
}

.has-mb-4 {
  margin-bottom: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-4-mobile {
    margin-bottom: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-4-tablet {
    margin-bottom: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-4-desktop {
    margin-bottom: 1rem !important;
  }
}

.has-mb-6 {
  margin-bottom: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-6-mobile {
    margin-bottom: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-6-tablet {
    margin-bottom: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-6-desktop {
    margin-bottom: 1.5rem !important;
  }
}

.has-mb-8 {
  margin-bottom: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-8-mobile {
    margin-bottom: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-8-tablet {
    margin-bottom: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-8-desktop {
    margin-bottom: 2rem !important;
  }
}

.has-mb-12 {
  margin-bottom: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-12-mobile {
    margin-bottom: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-12-tablet {
    margin-bottom: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-12-desktop {
    margin-bottom: 3rem !important;
  }
}

.has-mb-16 {
  margin-bottom: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-16-mobile {
    margin-bottom: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-16-tablet {
    margin-bottom: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-16-desktop {
    margin-bottom: 4rem !important;
  }
}

.has-mb-24 {
  margin-bottom: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-mb-24-mobile {
    margin-bottom: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-24-tablet {
    margin-bottom: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-24-desktop {
    margin-bottom: 6rem !important;
  }
}

.has-mb-none {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mb-none-mobile {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-none-tablet {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-none-desktop {
    margin-bottom: 0 !important;
  }
}

.has-mb-half {
  margin-bottom: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-mb-half-mobile {
    margin-bottom: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-half-tablet {
    margin-bottom: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-half-desktop {
    margin-bottom: 50% !important;
  }
}

.has-mb-full {
  margin-bottom: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-mb-full-mobile {
    margin-bottom: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-full-tablet {
    margin-bottom: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-full-desktop {
    margin-bottom: 100% !important;
  }
}

.has-mb-auto {
  margin-bottom: auto !important;
}

@media screen and (max-width: 640px) {
  .has-mb-auto-mobile {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mb-auto-tablet {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mb-auto-desktop {
    margin-bottom: auto !important;
  }
}

.has-ml-0 {
  margin-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-ml-0-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-0-tablet {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-0-desktop {
    margin-left: 0 !important;
  }
}

.has-ml-1 {
  margin-left: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-1-mobile {
    margin-left: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-1-tablet {
    margin-left: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-1-desktop {
    margin-left: .25rem !important;
  }
}

.has-ml-2 {
  margin-left: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-2-mobile {
    margin-left: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-2-tablet {
    margin-left: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-2-desktop {
    margin-left: .5rem !important;
  }
}

.has-ml-3 {
  margin-left: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-3-mobile {
    margin-left: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-3-tablet {
    margin-left: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-3-desktop {
    margin-left: .75rem !important;
  }
}

.has-ml-4 {
  margin-left: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-4-mobile {
    margin-left: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-4-tablet {
    margin-left: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-4-desktop {
    margin-left: 1rem !important;
  }
}

.has-ml-6 {
  margin-left: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-6-mobile {
    margin-left: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-6-tablet {
    margin-left: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-6-desktop {
    margin-left: 1.5rem !important;
  }
}

.has-ml-8 {
  margin-left: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-8-mobile {
    margin-left: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-8-tablet {
    margin-left: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-8-desktop {
    margin-left: 2rem !important;
  }
}

.has-ml-12 {
  margin-left: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-12-mobile {
    margin-left: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-12-tablet {
    margin-left: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-12-desktop {
    margin-left: 3rem !important;
  }
}

.has-ml-16 {
  margin-left: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-16-mobile {
    margin-left: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-16-tablet {
    margin-left: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-16-desktop {
    margin-left: 4rem !important;
  }
}

.has-ml-24 {
  margin-left: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-ml-24-mobile {
    margin-left: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-24-tablet {
    margin-left: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-24-desktop {
    margin-left: 6rem !important;
  }
}

.has-ml-none {
  margin-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-ml-none-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-none-tablet {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-none-desktop {
    margin-left: 0 !important;
  }
}

.has-ml-half {
  margin-left: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-ml-half-mobile {
    margin-left: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-half-tablet {
    margin-left: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-half-desktop {
    margin-left: 50% !important;
  }
}

.has-ml-full {
  margin-left: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-ml-full-mobile {
    margin-left: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-full-tablet {
    margin-left: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-full-desktop {
    margin-left: 100% !important;
  }
}

.has-ml-auto {
  margin-left: auto !important;
}

@media screen and (max-width: 640px) {
  .has-ml-auto-mobile {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-ml-auto-tablet {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-ml-auto-desktop {
    margin-left: auto !important;
  }
}

.has-mr-0 {
  margin-right: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mr-0-mobile {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-0-tablet {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-0-desktop {
    margin-right: 0 !important;
  }
}

.has-mr-1 {
  margin-right: .25rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-1-mobile {
    margin-right: .25rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-1-tablet {
    margin-right: .25rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-1-desktop {
    margin-right: .25rem !important;
  }
}

.has-mr-2 {
  margin-right: .5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-2-mobile {
    margin-right: .5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-2-tablet {
    margin-right: .5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-2-desktop {
    margin-right: .5rem !important;
  }
}

.has-mr-3 {
  margin-right: .75rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-3-mobile {
    margin-right: .75rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-3-tablet {
    margin-right: .75rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-3-desktop {
    margin-right: .75rem !important;
  }
}

.has-mr-4 {
  margin-right: 1rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-4-mobile {
    margin-right: 1rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-4-tablet {
    margin-right: 1rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-4-desktop {
    margin-right: 1rem !important;
  }
}

.has-mr-6 {
  margin-right: 1.5rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-6-mobile {
    margin-right: 1.5rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-6-tablet {
    margin-right: 1.5rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-6-desktop {
    margin-right: 1.5rem !important;
  }
}

.has-mr-8 {
  margin-right: 2rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-8-mobile {
    margin-right: 2rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-8-tablet {
    margin-right: 2rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-8-desktop {
    margin-right: 2rem !important;
  }
}

.has-mr-12 {
  margin-right: 3rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-12-mobile {
    margin-right: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-12-tablet {
    margin-right: 3rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-12-desktop {
    margin-right: 3rem !important;
  }
}

.has-mr-16 {
  margin-right: 4rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-16-mobile {
    margin-right: 4rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-16-tablet {
    margin-right: 4rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-16-desktop {
    margin-right: 4rem !important;
  }
}

.has-mr-24 {
  margin-right: 6rem !important;
}

@media screen and (max-width: 640px) {
  .has-mr-24-mobile {
    margin-right: 6rem !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-24-tablet {
    margin-right: 6rem !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-24-desktop {
    margin-right: 6rem !important;
  }
}

.has-mr-none {
  margin-right: 0 !important;
}

@media screen and (max-width: 640px) {
  .has-mr-none-mobile {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-none-tablet {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-none-desktop {
    margin-right: 0 !important;
  }
}

.has-mr-half {
  margin-right: 50% !important;
}

@media screen and (max-width: 640px) {
  .has-mr-half-mobile {
    margin-right: 50% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-half-tablet {
    margin-right: 50% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-half-desktop {
    margin-right: 50% !important;
  }
}

.has-mr-full {
  margin-right: 100% !important;
}

@media screen and (max-width: 640px) {
  .has-mr-full-mobile {
    margin-right: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-full-tablet {
    margin-right: 100% !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-full-desktop {
    margin-right: 100% !important;
  }
}

.has-mr-auto {
  margin-right: auto !important;
}

@media screen and (max-width: 640px) {
  .has-mr-auto-mobile {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 640px) {
  .has-mr-auto-tablet {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 1264px) {
  .has-mr-auto-desktop {
    margin-right: auto !important;
  }
}

.has-direction-row {
  flex-direction: row;
}

@media screen and (max-width: 640px) {
  .has-direction-row-mobile {
    flex-direction: row;
  }
}

@media screen and (min-width: 640px) {
  .has-direction-row-tablet {
    flex-direction: row;
  }
}

@media screen and (min-width: 1264px) {
  .has-direction-row-desktop {
    flex-direction: row;
  }
}

.has-direction-column {
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .has-direction-column-mobile {
    flex-direction: column;
  }
}

@media screen and (min-width: 640px) {
  .has-direction-column-tablet {
    flex-direction: column;
  }
}

@media screen and (min-width: 1264px) {
  .has-direction-column-desktop {
    flex-direction: column;
  }
}

.has-direction-row-rev {
  flex-direction: row-reverse;
}

@media screen and (max-width: 640px) {
  .has-direction-row-rev-mobile {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 640px) {
  .has-direction-row-rev-tablet {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 1264px) {
  .has-direction-row-rev-desktop {
    flex-direction: row-reverse;
  }
}

.has-direction-column-rev {
  flex-direction: column-reverse;
}

@media screen and (max-width: 640px) {
  .has-direction-column-rev-mobile {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 640px) {
  .has-direction-column-rev-tablet {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 1264px) {
  .has-direction-column-rev-desktop {
    flex-direction: column-reverse;
  }
}

.has-flex-grow {
  flex-grow: 1;
}

@media screen and (max-width: 640px) {
  .has-flex-grow-mobile {
    flex-grow: 1;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-grow-tablet {
    flex-grow: 1;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-grow-desktop {
    flex-grow: 1;
  }
}

.has-flex-no-grow {
  flex-grow: 0;
}

@media screen and (max-width: 640px) {
  .has-flex-no-grow-mobile {
    flex-grow: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-no-grow-tablet {
    flex-grow: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-no-grow-desktop {
    flex-grow: 0;
  }
}

.has-flex-shrink {
  flex-shrink: 1;
}

@media screen and (max-width: 640px) {
  .has-flex-shrink-mobile {
    flex-shrink: 1;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-shrink-tablet {
    flex-shrink: 1;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-shrink-desktop {
    flex-shrink: 1;
  }
}

.has-flex-no-shrink {
  flex-shrink: 0;
}

@media screen and (max-width: 640px) {
  .has-flex-no-shrink-mobile {
    flex-shrink: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-no-shrink-tablet {
    flex-shrink: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-no-shrink-desktop {
    flex-shrink: 0;
  }
}

.has-flex-wrap {
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .has-flex-wrap-mobile {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-wrap-tablet {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-wrap-desktop {
    flex-wrap: wrap;
  }
}

.has-flex-wrap-rev {
  flex-wrap: wrap-reverse;
}

@media screen and (max-width: 640px) {
  .has-flex-wrap-rev-mobile {
    flex-wrap: wrap-reverse;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-wrap-rev-tablet {
    flex-wrap: wrap-reverse;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-wrap-rev-desktop {
    flex-wrap: wrap-reverse;
  }
}

.has-flex-no-wrap {
  flex-wrap: nowrap;
}

@media screen and (max-width: 640px) {
  .has-flex-no-wrap-mobile {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 640px) {
  .has-flex-no-wrap-tablet {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 1264px) {
  .has-flex-no-wrap-desktop {
    flex-wrap: nowrap;
  }
}

.has-content-start {
  align-content: flex-start;
}

@media screen and (max-width: 640px) {
  .has-content-start-mobile {
    align-content: flex-start;
  }
}

@media screen and (min-width: 640px) {
  .has-content-start-tablet {
    align-content: flex-start;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-start-desktop {
    align-content: flex-start;
  }
}

.has-content-end {
  align-content: flex-end;
}

@media screen and (max-width: 640px) {
  .has-content-end-mobile {
    align-content: flex-end;
  }
}

@media screen and (min-width: 640px) {
  .has-content-end-tablet {
    align-content: flex-end;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-end-desktop {
    align-content: flex-end;
  }
}

.has-content-center {
  align-content: center;
}

@media screen and (max-width: 640px) {
  .has-content-center-mobile {
    align-content: center;
  }
}

@media screen and (min-width: 640px) {
  .has-content-center-tablet {
    align-content: center;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-center-desktop {
    align-content: center;
  }
}

.has-content-between {
  align-content: space-between;
}

@media screen and (max-width: 640px) {
  .has-content-between-mobile {
    align-content: space-between;
  }
}

@media screen and (min-width: 640px) {
  .has-content-between-tablet {
    align-content: space-between;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-between-desktop {
    align-content: space-between;
  }
}

.has-content-around {
  align-content: space-around;
}

@media screen and (max-width: 640px) {
  .has-content-around-mobile {
    align-content: space-around;
  }
}

@media screen and (min-width: 640px) {
  .has-content-around-tablet {
    align-content: space-around;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-around-desktop {
    align-content: space-around;
  }
}

.has-content-evenly {
  align-content: space-evenly;
}

@media screen and (max-width: 640px) {
  .has-content-evenly-mobile {
    align-content: space-evenly;
  }
}

@media screen and (min-width: 640px) {
  .has-content-evenly-tablet {
    align-content: space-evenly;
  }
}

@media screen and (min-width: 1264px) {
  .has-content-evenly-desktop {
    align-content: space-evenly;
  }
}

.has-items-start {
  align-items: flex-start;
}

@media screen and (max-width: 640px) {
  .has-items-start-mobile {
    align-items: flex-start;
  }
}

@media screen and (min-width: 640px) {
  .has-items-start-tablet {
    align-items: flex-start;
  }
}

@media screen and (min-width: 1264px) {
  .has-items-start-desktop {
    align-items: flex-start;
  }
}

.has-items-end {
  align-items: flex-end;
}

@media screen and (max-width: 640px) {
  .has-items-end-mobile {
    align-items: flex-end;
  }
}

@media screen and (min-width: 640px) {
  .has-items-end-tablet {
    align-items: flex-end;
  }
}

@media screen and (min-width: 1264px) {
  .has-items-end-desktop {
    align-items: flex-end;
  }
}

.has-items-center {
  align-items: center;
}

@media screen and (max-width: 640px) {
  .has-items-center-mobile {
    align-items: center;
  }
}

@media screen and (min-width: 640px) {
  .has-items-center-tablet {
    align-items: center;
  }
}

@media screen and (min-width: 1264px) {
  .has-items-center-desktop {
    align-items: center;
  }
}

.has-items-stretch {
  align-items: stretch;
}

@media screen and (max-width: 640px) {
  .has-items-stretch-mobile {
    align-items: stretch;
  }
}

@media screen and (min-width: 640px) {
  .has-items-stretch-tablet {
    align-items: stretch;
  }
}

@media screen and (min-width: 1264px) {
  .has-items-stretch-desktop {
    align-items: stretch;
  }
}

.has-items-baseline {
  align-items: baseline;
}

@media screen and (max-width: 640px) {
  .has-items-baseline-mobile {
    align-items: baseline;
  }
}

@media screen and (min-width: 640px) {
  .has-items-baseline-tablet {
    align-items: baseline;
  }
}

@media screen and (min-width: 1264px) {
  .has-items-baseline-desktop {
    align-items: baseline;
  }
}

.has-self-auto {
  align-self: auto;
}

@media screen and (max-width: 640px) {
  .has-self-auto-mobile {
    align-self: auto;
  }
}

@media screen and (min-width: 640px) {
  .has-self-auto-tablet {
    align-self: auto;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-auto-desktop {
    align-self: auto;
  }
}

.has-self-start {
  align-self: flex-start;
}

@media screen and (max-width: 640px) {
  .has-self-start-mobile {
    align-self: flex-start;
  }
}

@media screen and (min-width: 640px) {
  .has-self-start-tablet {
    align-self: flex-start;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-start-desktop {
    align-self: flex-start;
  }
}

.has-self-end {
  align-self: flex-end;
}

@media screen and (max-width: 640px) {
  .has-self-end-mobile {
    align-self: flex-end;
  }
}

@media screen and (min-width: 640px) {
  .has-self-end-tablet {
    align-self: flex-end;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-end-desktop {
    align-self: flex-end;
  }
}

.has-self-center {
  align-self: center;
}

@media screen and (max-width: 640px) {
  .has-self-center-mobile {
    align-self: center;
  }
}

@media screen and (min-width: 640px) {
  .has-self-center-tablet {
    align-self: center;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-center-desktop {
    align-self: center;
  }
}

.has-self-stretch {
  align-self: stretch;
}

@media screen and (max-width: 640px) {
  .has-self-stretch-mobile {
    align-self: stretch;
  }
}

@media screen and (min-width: 640px) {
  .has-self-stretch-tablet {
    align-self: stretch;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-stretch-desktop {
    align-self: stretch;
  }
}

.has-self-baseline {
  align-self: baseline;
}

@media screen and (max-width: 640px) {
  .has-self-baseline-mobile {
    align-self: baseline;
  }
}

@media screen and (min-width: 640px) {
  .has-self-baseline-tablet {
    align-self: baseline;
  }
}

@media screen and (min-width: 1264px) {
  .has-self-baseline-desktop {
    align-self: baseline;
  }
}

.has-justify-start {
  justify-content: flex-start;
}

@media screen and (max-width: 640px) {
  .has-justify-start-mobile {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-start-tablet {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-start-desktop {
    justify-content: flex-start;
  }
}

.has-justify-end {
  justify-content: flex-end;
}

@media screen and (max-width: 640px) {
  .has-justify-end-mobile {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-end-tablet {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-end-desktop {
    justify-content: flex-end;
  }
}

.has-justify-center {
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .has-justify-center-mobile {
    justify-content: center;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-center-tablet {
    justify-content: center;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-center-desktop {
    justify-content: center;
  }
}

.has-justify-between {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .has-justify-between-mobile {
    justify-content: space-between;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-between-tablet {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-between-desktop {
    justify-content: space-between;
  }
}

.has-justify-around {
  justify-content: space-around;
}

@media screen and (max-width: 640px) {
  .has-justify-around-mobile {
    justify-content: space-around;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-around-tablet {
    justify-content: space-around;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-around-desktop {
    justify-content: space-around;
  }
}

.has-justify-evenly {
  justify-content: space-evenly;
}

@media screen and (max-width: 640px) {
  .has-justify-evenly-mobile {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 640px) {
  .has-justify-evenly-tablet {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1264px) {
  .has-justify-evenly-desktop {
    justify-content: space-evenly;
  }
}

.has-order-none {
  order: 0;
}

@media screen and (max-width: 640px) {
  .has-order-none-mobile {
    order: 0;
  }
}

@media screen and (min-width: 640px) {
  .has-order-none-tablet {
    order: 0;
  }
}

@media screen and (min-width: 1264px) {
  .has-order-none-desktop {
    order: 0;
  }
}

.has-order-first {
  order: -999;
}

@media screen and (max-width: 640px) {
  .has-order-first-mobile {
    order: -999;
  }
}

@media screen and (min-width: 640px) {
  .has-order-first-tablet {
    order: -999;
  }
}

@media screen and (min-width: 1264px) {
  .has-order-first-desktop {
    order: -999;
  }
}

.has-order-last {
  order: 999;
}

@media screen and (max-width: 640px) {
  .has-order-last-mobile {
    order: 999;
  }
}

@media screen and (min-width: 640px) {
  .has-order-last-tablet {
    order: 999;
  }
}

@media screen and (min-width: 1264px) {
  .has-order-last-desktop {
    order: 999;
  }
}

.has-text-justified {
  text-align: justify;
}

.has-text-left {
  text-align: left;
}

.has-text-center {
  text-align: center;
}

.has-text-right {
  text-align: right;
}

.has-align-top {
  vertical-align: top;
}

.has-align-middle {
  vertical-align: middle;
}

.has-align-bottom {
  vertical-align: bottom;
}

.is-clickable {
  cursor: pointer !important;
}

.is-not-allowed {
  cursor: not-allowed !important;
}

.is-block {
  display: block;
}

@media screen and (max-width: 640px) {
  .is-block-mobile {
    display: block;
  }
}

@media screen and (min-width: 640px) {
  .is-block-tablet {
    display: block;
  }
}

@media screen and (min-width: 1264px) {
  .is-block-desktop {
    display: block;
  }
}

.is-flex {
  display: flex;
}

@media screen and (max-width: 640px) {
  .is-flex-mobile {
    display: flex;
  }
}

@media screen and (min-width: 640px) {
  .is-flex-tablet {
    display: flex;
  }
}

@media screen and (min-width: 1264px) {
  .is-flex-desktop {
    display: flex;
  }
}

.is-inline {
  display: inline;
}

@media screen and (max-width: 640px) {
  .is-inline-mobile {
    display: inline;
  }
}

@media screen and (min-width: 640px) {
  .is-inline-tablet {
    display: inline;
  }
}

@media screen and (min-width: 1264px) {
  .is-inline-desktop {
    display: inline;
  }
}

.is-inline-block {
  display: inline-block;
}

@media screen and (max-width: 640px) {
  .is-inline-block-mobile {
    display: inline-block;
  }
}

@media screen and (min-width: 640px) {
  .is-inline-block-tablet {
    display: inline-block;
  }
}

@media screen and (min-width: 1264px) {
  .is-inline-block-desktop {
    display: inline-block;
  }
}

.is-inline-flex {
  display: inline-flex;
}

@media screen and (max-width: 640px) {
  .is-inline-flex-mobile {
    display: inline-flex;
  }
}

@media screen and (min-width: 640px) {
  .is-inline-flex-tablet {
    display: inline-flex;
  }
}

@media screen and (min-width: 1264px) {
  .is-inline-flex-desktop {
    display: inline-flex;
  }
}

.is-hidden {
  display: none;
}

@media screen and (max-width: 640px) {
  .is-hidden-mobile {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .is-hidden-tablet {
    display: none;
  }
}

@media screen and (min-width: 1264px) {
  .is-hidden-desktop {
    display: none;
  }
}

.is-aligned-left {
  float: left;
}

.is-aligned-right {
  float: right;
}

.is-clipped {
  overflow: hidden !important;
}

.is-scrollable {
  overflow: auto !important;
}

.is-shadowed {
  box-shadow: 0 .5rem 1rem -.25rem #363f4f33, 0 0 0 1px #363f4f05;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-rounded {
  border-radius: .5rem;
}

.is-pill {
  border-radius: 9999px;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.is-sticky {
  position: sticky;
}

.is-fixed {
  position: fixed;
}

.is-top {
  top: 0;
}

.is-bottom {
  bottom: 0;
}

.is-italic {
  font-style: italic;
}

.is-underlined {
  text-decoration: underline;
}

.is-not-underlined {
  text-decoration: none !important;
}

.is-capitalized {
  text-transform: capitalize;
}

.is-lowercase {
  text-transform: lowercase;
}

.is-uppercase {
  text-transform: uppercase;
}

.is-semitransparent {
  opacity: .5;
}

.is-transparent {
  opacity: 0;
}

.is-unselectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.button-link {
  color: #fff;
  text-transform: uppercase;
  background-color: #1272ce;
  padding: 6px 12px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
}

.button-link:hover {
  color: #fff;
}

.button-link.is-mixedcase {
  text-transform: none;
}

.has-gap-1 {
  gap: 1rem;
}

/*# sourceMappingURL=404.269b8711.css.map */
